<template>
<div class="container-md col-lg-8 col-md-8">
    <AddCustomer @submit="registerCustomer"/>
</div>
</template>
<script>
import AddCustomer from "@/containers/Customer/AddCustomer";
import { mapActions } from 'vuex';
export default {
  components: {
    AddCustomer,
  },
  methods:{
    ...mapActions(["addCustomer"]),
    registerCustomer(data){
      this.addCustomer(data);
    }
  }
};
</script>